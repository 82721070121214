import { initUrqlClient } from 'next-urql'
import { LOCALES, PAGE_TYPES } from '../constants'
import { homePage, landingPage, simpleContentPage, authorPage } from '../controllers'
import { templates } from '../templates'
const { BASE_PATH } = process.env

const generatePageConfig = (slug) => {
  const fragments = [...slug]
  return {
    locale: (() => {
      if (LOCALES.has(fragments[0])) {
        return LOCALES.get(fragments.shift())
      }

      return LOCALES.get('gb')
    })(),
    pageType: (() => {
      if (PAGE_TYPES.has(fragments[0])) {
        if (PAGE_TYPES.get(fragments[0]) === PAGE_TYPES.get('ilp')) {
          return undefined
        }

        return PAGE_TYPES.get(fragments.shift())
      }

      if (fragments.length) {
        return PAGE_TYPES.get('ilp')
      }

      return PAGE_TYPES.get('hp')
    })(),
    ilpSlug: fragments
  }
}
export const getServerSideProps = async ({ req, query }) => {
  const client = initUrqlClient(
    {
      url: process.env.NEXT_PUBLIC_CMS_ENDPOINT
    },
    false
  )

  const host = req.headers.host
  const { slug = [] } = query
  const { locale, pageType, ilpSlug } = generatePageConfig(slug)
  switch (pageType) {
    case 'ilp': {
      return landingPage({ client, locale, ilpSlug, slug, host, query, baseUrl: BASE_PATH })
    }
    case 'cp': {
      return simpleContentPage({ client, pageSlug: slug.slice(-1)[0], locale, slug })
    }
    case 'hp': {
      return homePage({ client, locale, host })
    }
    case 'author': {
      const authorIdRegex = /^\d+/
      const id = ilpSlug.toString().match(authorIdRegex)
      if (id) {
        return authorPage({
          client,
          locale,
          authorId: id,
          page: query.page ? query.page : 1,
          baseUrl: BASE_PATH
        })
      }
    }
    default: {
      return {
        notFound: true
      }
    }
  }
}

const Index = ({ template, pageProps }) => {
  const Page = templates[template]
  return <Page {...pageProps} />
}

export default Index
